import { contactSubmit } from './contact.js';
import {getMapsHelper} from './util/mapsHelper';

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
  
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
  
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

const mapHelper = getMapsHelper();

mapHelper.ready()
	.then(() => {
		const theMap = mapHelper.createMap({
			element: document.querySelector('#map_canvas'),
			locationElementSelector: '#map_canvas',
		});

		// eslint-disable-next-line no-undef
		google.maps.event.addListenerOnce(theMap, 'idle', () => {
			theMap.setZoom(15);
		});
	});


if (document.getElementById('contact_form')) {
	contactSubmit();
}
